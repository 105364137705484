/*global
	jQuery, CookieControl
 */

import { CookieControlDispatcher } from './js/cookie-control-dispatcher.class';
import { CookieControlConsent }    from './js/cookie-control-consent.class';
import { CookieControlBanner }     from './js/cookie-control-banner.class';
import './js/cookie-control-config';

let $ = jQuery;

window.CookieControlDispatcher = CookieControlDispatcher;
window.CookieControlConsent    = CookieControlConsent;
window.CookieControlBanner     = CookieControlBanner;

console.log('%cRunning ccc:'+'%c0.4.2', 'color:blue', 'color:blue;font-weight:700');

function wait_for_cookie_control(cb) {

	if($('body').children('#ccc').length) {
		typeof cb == 'function' && cb();
	} else {
		setTimeout(() => wait_for_cookie_control(cb), 50);
	}

}

// set a timeout so other scripts can modify cookieControlConfig before it's executed
setTimeout(() => {

  CookieControl.load(window.cookieControlConfig);
  CookieControl.hide();

  wait_for_cookie_control(() => {

  	new CookieControlBanner();
		jQuery('body').trigger('ccc-loaded');
		document.querySelector('body').dataset.cccloaded = '1';

  });

}, 500);
